import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DeviceDashboardService } from './DeviceDashboardService';

export const useDeviceDashboardSimple = () => {  

    const { deviceid } = useParams();

    const [device, setDevice] = useState(null);
    const [metersCfg, setMeterConfig] = useState([]);
    const [metersState, setMeterState] = useState([]);

    function MeterDimensionToText(dim_idx)
    {
        switch (dim_idx)
        {
            case 0:
                return "kWh";
            case 1:
                return "MWh";
            case 2:
                return "W";
            case 3:
                return "m3";
            case 4:
                return "Wh";
            default:
                return "???";
        }
    }
    
    
    function GetMeterTypeText(type_idx)
    {
        switch (type_idx)
        {
            case 0:
                return "Electricity-Main";
            case 1:
                return "Electricity-Sub";
            case 2:
                return "Water-Main";
            case 3:
                return "Water-Sub";
            case 4:
                return "Gas-Main";
            case 5:
                return "Gas-Sub";
            case 6:
                return "HeatMeter";
            default:
                return "???";
        }
    }
    
    
    function MeterInputModeToText(inputmode, longtext = false)
    {
        switch (inputmode)
        {
            case 0:
                return (!longtext) ? "PC" : "Pulse Counting";
            case 1:
                return "MBUS";
            case 2:
                return "MODB";
            case 3:
                return (!longtext) ? "VIRT" : "Virtual Meter";
            default:
                return "???";
        }
    }  
    
    function getMeterConfig(cfg, meterid)
    {
        
        for (let i = 0; i < cfg.length; i++) {
            const r = cfg[i];
        
            if(r.MeterId != meterid)
                continue;

            return JSON.parse(r.FileContent);
        }
        return null;
    }

    useEffect(() => {
        const loadData = async () => {
            const device = await DeviceDashboardService.getDeviceData(deviceid);
            setDevice(device);

            const metersCfg = await DeviceDashboardService.getMetersOfDevice(deviceid);
            setMeterConfig(metersCfg);

            const metersState = await DeviceDashboardService.getMetersStateOfDevice(deviceid);
            setMeterState(metersState);    

            return Promise.resolve({device, metersCfg, metersState});
        };

        if (deviceid) 
        {
            loadData().then(data => 
                {                    
                    console.log("loaded");
                    let mstatewithcfg = [];
                    data.metersState.forEach(mv => {
                        let cfg = getMeterConfig(data.metersCfg, mv.MeterId);
                        if(!cfg)
                           console.log("cfg not found: " + mv.MeterId);
                        else
                           console.log(mv.MeterId +" -- " + cfg.counterName);

                        mv.config = cfg;
                        mv.dimensionText = MeterDimensionToText(cfg.dimension);
                        mv.meterTypeText = GetMeterTypeText(cfg.meterType);
                        mv.inputModeText = MeterInputModeToText(cfg.inputMode);

                        mstatewithcfg.push(mv);
                     })

                     setMeterState(mstatewithcfg);
                }

            );
        }

    }, [deviceid]);

    return {
        device,
        metersCfg,
        metersState
    };
};
