import { FetchData } from "./components/FetchData";
import Home from "./components/Home"
import { DeviceDashboardSimple } from "./meters/DeviceDashboardSimple"

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/metercenter/:deviceid',
      element: <DeviceDashboardSimple />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
    }
];

export default AppRoutes;
