import { useDeviceDashboardSimple } from './useDeviceDashboardSimple';
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import DateFormatter from "../shared/dateformatter"


export const DeviceDashboardSimple = () => {
    const displayName = "MMC";

    const { deviceid } = useParams();

    const { device, meters, metersState } = useDeviceDashboardSimple();

    
    return (
        <div>
            <h3>Eszköz Adatai</h3>

            <table className="table table-striped" aria-labelledby="tableLabel">
                <tbody>
                    <tr >
                        <td>Eszköz</td>
                        <td>{device?.name} / {deviceid}</td>
                    </tr>
                    <tr >
                        <td>Eszköz indulása</td>
                        <td>{DateFormatter({ date: new Date(device?.updatedAt), colorize: true, showElapsedTime: true, isUTC: true })}</td>
                        
                    </tr>
                    <tr >
                        <td>Hálózat</td>
                        <td>{device?.localIP} / {device?.localMAC}</td>
                    </tr>
                    <tr >
                        <td>Egyebek</td>
                        <td>{device?.metadata}</td>
                    </tr>                    
                    
                </tbody>
            </table>

                
        <h3>Állapot</h3>            
        {/* 
            {"DeviceId":"bfcb6c17-28dc-43f3-b942-59f0b8e48129","MeterId":1,
            "DCreatedAt":"2023-06-23T22:17:07","SCreatedAt":"2023-06-23T20:17:08.4272499",
            "MeterValue":0,"MeterPulseCount":4,"IsValid":false},        
        */}                
        <table className="table table-striped" aria-labelledby="tableLabel">
            <thead>
                <tr>
                    <th>Mérő</th>
                    <th>Érték</th>
                    <th>Frissítve</th>
                    <th>Impulzus</th>
                    <th>Érvényes</th>
                </tr>
            </thead>
            <tbody>
                {metersState.map(ms =>
                    <tr key={ms?.MeterId}>
                       <td>{ms?.MeterId}  {ms?.config?.counterName} {ms?.inputModeText}</td>
                       <td>{ms?.MeterValue} {ms?.dimensionText}</td>
                       <td>{DateFormatter({date: new Date(ms?.DCreatedAt), colorize: true, showElapsedTime: true})}</td>
                       <td>{ms?.MeterPulseCount}</td>
                       <td>{ms?.IsValid}</td>
                    </tr>
                )}

                
            </tbody>
            </table>
        </div>
    );
};

