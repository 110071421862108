const BASE_URL = '/api/mmc';

export const DeviceDashboardService = {

    // "id": "bfcb6c17-28dc-43f3-b942-59f0b8e48129",    
    // "enabled": true,
    // "name": "MMC16-OV14,Halasztelek",
    // "location": null,
    // "type": "MMC16",
    // "createdBy": null,
    // "createdAt": "2023-06-17T22:10:26.2366667",
    // "installedAt": "2023-06-17T22:10:26.2366667",
    // "updatedAt": "2023-06-23T08:07:12.3147259",
    // "localIP": "192.168.0.122",
    // "localMAC": "24:4C:AB:28:8D:57",
    // "metadata": "startedat:2023-05-18 23:29:36, firmware:1.200000, serial:MMC16-230001",
    // "userPermissions": null    

    // https://localhost:9501/api/mmc/BFCB6C17-28DC-43F3-B942-59F0B8E48129
    async getDeviceData(deviceId) {
        const response = await fetch(`${BASE_URL}/${deviceId}`);
        return await response.json();        
    },

    /*
    
    [{"DeviceId":"bfcb6c17-28dc-43f3-b942-59f0b8e48129",
      "MeterId":6,
      "FileContent":
        "{\"meterId\":6,
        \"inputMode\":0,
        \"meterType\":1,
        \"dimension\":0,
        \"pulseToDimensionRate
        \":0.100000001,
        \"decimalCount\":1,
        \"counterName\":\"Villamosaram: A1(fsz,thai masszazs)\",
        \"counterType\":\"STILO STI491\",
        \"counterSerial\":\"200911788\",
        \"counterLocation\":\"Gepeszeti blokk, kazannal szemben\",
        \"counterCable\":\"arnyekolt 2 eres szurke 2x1mm2\",
        \"sensor\":\"beepitett\",
        \"storeChangesToSD\":false,
        \"storeChangesEveryXMin\":0,
        \"pulseInputChannel\":5,
        \"sourceMeterId\":0,
        \"comments\":\"\",
        \"deviceAddress\":\"\",
        \"meterValueQueryString\":\"\"
        }"
        },    
    
    */

    // https://localhost:44457/api/mmc/BFCB6C17-28DC-43F3-B942-59F0B8E48129/meters
    async getMetersOfDevice(deviceId) {
        const response = await fetch(`${BASE_URL}/${deviceId}/meters`);
        return await response.json();
    },

    // https://localhost:44457/api/mmc/BFCB6C17-28DC-43F3-B942-59F0B8E48129/meterstate
    /*
        [{"DeviceId":"bfcb6c17-28dc-43f3-b942-59f0b8e48129","MeterId":1,"DCreatedAt":"2023-06-23T22:17:07","SCreatedAt":"2023-06-23T20:17:08.4272499","MeterValue":0,"MeterPulseCount":4,"IsValid":false},
         {"DeviceId":"bfcb6c17-28dc-43f3-b942-59f0b8e48129","MeterId":2,"DCreatedAt":"2023-06-23T22:16:59","SCreatedAt":"2023-06-23T20:17:00.7997634","MeterValue":144.005,"MeterPulseCount":10,"IsValid":true}
         ..
         ]
    */
    async getMetersStateOfDevice(deviceId) {
        const response = await fetch(`${BASE_URL}/${deviceId}/meterstate`);
        return await response.json();
    },
    



};
