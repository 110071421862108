import React, { Component } from 'react';
import DateFormatter from '../shared/dateformatter';
import dayjs from "dayjs";

export default function Home() {
  
  function createTestDates()
  {
    let now = dayjs(new Date());
    let testDates = [];
        
    testDates.push({ key: 0, date: now.toDate()});
    testDates.push({ key: 1, date: now.add(-5, 'second').toDate() }) ;
    testDates.push({ key: 2, date: now.add(-30, 'second').toDate() }) ;
    testDates.push({ key: 3, date: now.add(-60, 'second').toDate() }) ;
    testDates.push({ key: 4, date: now.add(-120, 'second').toDate() }) ;
    testDates.push({ key: 5, date: now.add(-240, 'second').toDate() }) ;
    testDates.push({ key: 6, date: now.add(-5, 'minute').toDate() }) ;
    testDates.push({ key: 7, date: now.add(-10, 'minute').toDate() }) ;
    testDates.push({ key: 8, date: now.add(-30, 'minute').toDate() }) ;
    testDates.push({ key: 9, date: now.add(-1, 'hour').toDate() }) ;
    testDates.push({ key: 10, date: now.add(-2, 'hour').toDate() }) ;
    testDates.push({ key: 11, date: now.add(-3, 'hour').toDate() }) ;
    testDates.push({ key: 12, date: now.add(-6, 'hour').toDate() }) ;
    testDates.push({ key: 13, date: now.add(-24, 'hour').toDate() }) ;
    testDates.push({ key: 14, date: now.add(-1, 'day').toDate() }) ;
    testDates.push({ key: 15, date: now.add(-2, 'day').toDate() }) ;
    testDates.push({ key: 16, date: now.add(-3, 'day').toDate() }) ;
    testDates.push({ key: 17, date: now.add(-20, 'day').toDate() }) ;

    return testDates;
  }

  const td = createTestDates();
  const list = td.map(d => <div>{dayjs(d.date).format("YYYY.MM.DD. HH:mm:ss")}  {DateFormatter({date: d.date, colorize: true, showElapsedTime: true}) } </div>)
  
  return <div>
          {list}
          
          {/* Date:
          { DateFormatter({date: "2023.07.01 01:24:12", colorize: true, showElapsedTime: true}) } */}
      </div>;
      
}
