import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default function DateFormatter({ date, showElapsedTime, colorize, isUTC }) {

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.guess();

    let dateValue;
    if(!date)
        return "";

    if (date instanceof Date)
        dateValue = date;
    else if (date instanceof String)
        dateValue = new Date(Date.parse(date));
    else
        return <span>{date}</span>;  // in case of unparsable data - just return

    let daysj_date = dayjs(dateValue); // 

    if (isUTC)
    {
        daysj_date = dayjs.utc(dateValue).local().tz("Europe/Budapest"); // fix timezone because the returned date is in UTC
        daysj_date = daysj_date.add(2, 'hour');
    }
    

    let now = new Date();
    let onSameDay = false;

    if (now.getFullYear() === daysj_date.year()
        &&
        now.getMonth() === daysj_date.month()
        &&
        now.getDay() === daysj_date.day()) {
        onSameDay = true;
    }

    let dateString = "";

    

    if (onSameDay)
        dateString = daysj_date.format("HH:mm:ss");
    else
        dateString = daysj_date.format("YYYY.MM.DD. HH:mm:ss");


    function getDateDiffDescription(now, dateValue) {
        let diffMilliSec = now - dateValue;
        let diffTotalSec = Math.floor(diffMilliSec / 1000);
        let diffTotalMins = Math.floor(diffTotalSec / 60);
        let diffTotalHours = Math.floor(diffTotalMins / 60);
        let diffTotalDays = Math.floor(diffTotalHours / 24);

        if (diffTotalSec < 60)
            return diffTotalSec + " sec";
        else if (diffTotalMins < 60)
            return diffTotalMins + " min";
        else if (diffTotalHours < 24)
            return diffTotalHours + " h";
        else if (diffTotalDays < 24)
            return diffTotalDays + " d";
    }

    function getActivityBackgroundColor(elapsedSeconds) {

        var diff = elapsedSeconds / 60;

        if (diff < 2)
            return "#7CFC00";

        if (diff >= 2 && diff < 3)
            return "#00FF00";

        if (diff >= 3 && diff < 5)
            return "#32CD32";

        if (diff >= 5 && diff < 10)
            return "#228B22";

        if (diff >= 10 && diff < 30)
            return "#8FBC8F";

        if (diff >= 30 && diff < 120)
            return "#567156";

        return "#ffffff";
    }


    function colorBasedOnElapsedTime(now, dateValue) {
        let diffMilliSec = now - dateValue;
        let diffTotalSec = Math.floor(diffMilliSec / 1000);
        let diffTotalMins = Math.floor(diffTotalSec / 60);
        let diffTotalHours = Math.floor(diffTotalMins / 60);
        let diffTotalDays = Math.floor(diffTotalHours / 24);

        if (diffTotalSec <= 60)
            return "#88db38"; // nagyon �l�nk z�ld
        else if (diffTotalSec > 60 && diffTotalSec <= 3*60)  // 1 - 3 perc
            return "#21d975"; // �l�nk z�ld
        else if (diffTotalSec > 3*60 && diffTotalSec <= 10*60) // 3 perc - 10 perc
            return "#41b31a";  // s�t�tz�ld
        else if (diffTotalSec > 10*60 && diffTotalSec <= 60*60) // 10 perc - 1h 
            return "#2c6f2c";  // s�t�tz�ld
        else if (diffTotalSec > 60*60 && diffTotalSec <= 4* 60*60) // 1h -  4h
            return "#7f877c";  // sz�rk�sz�ld
        else 
            return "#8c8987";  // sz�rke
        
    }


    if (showElapsedTime)
        dateString += " (" + getDateDiffDescription(now, daysj_date.toDate()) + ")";


    if (colorize)
    {
        return <span style={{ color: colorBasedOnElapsedTime(now, daysj_date.toDate()) }} >
               { dateString }
               </span>;
    }
    else
    {
        return <span> {dateString} </span>;
    }

    
}
